import { Injectable, inject, signal } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { LoadingService } from './loading.service';
import { Observable, finalize, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  BASE_URL = environment.CLIENT_BASE_URL + '/v1';

  http = inject(HttpClient);
  private loadingService = inject(LoadingService);
  constructor() { }

  
  getBankList(): Observable<BankListResponse> {
    const uniqueStateUrl = 'get-bank-list';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .get<BankListResponse>(this.BASE_URL + '/bank/get-bank-list')
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  async getBankName(bankCode: string): Promise<string | undefined> {
    const uniqueStateUrl = 'get-bank-list';
    this.loadingService.setLoading(uniqueStateUrl, true);
    try {
      // Convert the observable to a promise
      const res = await firstValueFrom(this.getBankList());

      const banks = res.data as { bankName: string; bankCode: string }[];

      const bank = banks.find((bank) => bank.bankCode === bankCode);

      return bank ? bank.bankName : undefined;
    } catch (error) {
      console.error('Error fetching bank name:', error);
      return undefined;
    } finally {
      this.loadingService.setLoading(uniqueStateUrl, false);
    }
  }

 

  validateBankAccountNo(
    accountNumber: string,
    bankCode: string
  ): Observable<AccountValidationResponse> {
    const uniqueStateUrl = 'validate-bank-details';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post<AccountValidationResponse>(
        this.BASE_URL + '/bank/validate-bank-details',
        {
          account_number: accountNumber,
          bank_code: bankCode,
        }
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

}

interface AccountValidationResponse {
  status: string;
  message: string;
  data: AccountValidationResponseData;
}

interface AccountValidationResponseData {
  bankCode: string;
  accountName: string;
  transactionReference: string;
  bvn: string;
  responseMessage: string;
  accountNumber: string;
  responseCode: string;
}

interface BankListResponse {
  status: string;
  message: string;
  data: BankListDatum[];
}

interface BankListDatum {
  bankCode: string;
  bankName: string;
}
